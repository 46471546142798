/* HEADLINE FONTS */
@font-face {
  font-family: 'dolce_vita';
  src: url('./fonts/dolce_vita-webfont.woff2') format('woff2'),
  url('./fonts/dolce_vita-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
body .App .output *.dolce_vita {
  font-family: 'dolce_vita', 'Arial', sans-serif !important;
  letter-spacing: 2px;
}
@font-face {
  font-family: 'dolce_vita_heavybold';
  src: url('./fonts/dolce_vita_heavy_bold-webfont.woff2') format('woff2'),
  url('./fonts/dolce_vita_heavy_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'synthetic';
  src: url('./fonts/synthetic-webfont.woff2') format('woff2'),
  url('./fonts/synthetic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body .App .output *.synthetic {
  font-family: 'synthetic', 'Arial', sans-serif !important;
  letter-spacing: 3px;
  font-size: 2.3rem;
}
@font-face {
  font-family: 'flick';
  src: url('./fonts/flick_-_ss3_personal_used-webfont.woff2') format('woff2'),
  url('./fonts/flick_-_ss3_personal_used-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body .App .output *.flick {
  font-family: 'flick', 'Arial', sans-serif !important;
  font-weight: normal !important;
}

/* CHALLENGE FONTS */
@font-face {
  font-family: 'open_sans';
  src: url('./fonts/opensans-variablefont_wdthwght-webfont.woff2') format('woff2'),
  url('./fonts/opensans-variablefont_wdthwght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body .App .output *.open_sans,
body .App .output main.open_sans .ChallengeControl .display > button {
  font-family: 'open_sans', 'Arial', sans-serif !important;
}
@font-face {
  font-family: 'roboto_slab';
  src: url('./fonts/robotoslab-variablefont_wght-webfont.woff2') format('woff2'),
  url('./fonts/robotoslab-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body .App .output *.roboto_slab,
body .App .output main.roboto_slab .ChallengeControl .display > button {
  font-family: 'roboto_slab', 'Arial', sans-serif !important;
}
@font-face {
  font-family: 'vina_sans';
  src: url('./fonts/vinasans-regular-webfont.woff2') format('woff2'),
  url('./fonts/vinasans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body .App .output *.vina_sans,
body .App .output main.vina_sans .ChallengeControl .display > button {
  font-family: 'vina_sans', 'Arial', sans-serif !important;
  letter-spacing: 1px;
}

*.hide { display: none; }
button { cursor: pointer; }

.App {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: #3c3c3c;
  font-family: 'open_sans', 'Arial', sans-serif;
}

nav {
  padding: 1rem;
  background: #284B7E;
}
nav img {
  max-width: 50%;
}
nav button {
  background: transparent;
  border: 0;
  font-size: 2rem;
  float: right;
  color: #ffffff;
  margin-top: 1rem;
}

.Tray {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  transition: all .5s ease-in-out;
  width: 100%;
  height: 39rem;
  z-index: 3;
}
.Tray.closed {
  bottom: -34.5rem;
  transition: all .5s ease-in-out;
}
.Tray .wrapper {
  background: #284B7E;
  padding-top: 0.25rem;
  height: 34rem;
}
.Tray .wrapper .space {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  max-width: 40rem;
  margin: 1rem auto 0;
}
.Tray .full {
  width: 100%;
}
.Tray .half {
  width: 50%;
}
.Tray .third {
  width: 33%;
}
.Tray .third:not(:first-child) {
  text-align: center;
  align-self: center;
}
.Tray label {
  margin: .25rem 0;
  padding: 0 0 0 .25rem;
  font-weight: bold;
}
.Tray input.title {
  width: 97%;
  margin: .25rem auto;
  background: #213C6D;
  color: #ffffff;
  border: 0;
  clear: both;
  padding: .25rem .5rem;
  font-size: 1.2rem;
}
.Tray select {
  width: 98%;
  margin: .25rem auto;
  background: #213C6D;
  color: #ffffff;
  border: 0;
  clear: both;
  padding: .25rem;
  font-size: 1.2rem;
}
.Tray .font {
  display: flex;
  flex-wrap: wrap;
}
.Tray .font label {
  width: 100%;
}
.Tray .font select {
  width: 90%;
}
.Tray .font button {
  background: transparent;
  color: #ffffff;
  border: 0;
  font-size: 1rem;
}
.Tray .backgrounds {
  display: flex;
  flex-wrap: wrap;
}
.Tray .backgrounds button {
  border: 0;
  background: transparent;
  width: 20%;
}
.Tray .backgrounds button img {
  width: 100%;
}
.Tray button.openClose {
  width: 100%;
  height: 5rem;
  border: 0;
  padding: 0 0 2rem;
  font-size: 3rem;
  color: #ffffff;
  text-shadow: 1px 1px 3px #000000;
  background: transparent;
}
.Tray button.openClose svg {
  animation: shake 1s linear infinite;
}
.Tray button.openClose path {
  stroke: #000000;
  stroke-width: 10px;
}
.Tray .third button {
  background: #213C6D;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  border: 0;
  margin: 0 .25rem;
  padding: .5rem 1rem;
}

.About {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #284B7E;
  padding: 1rem;
  color: #ffffff;
  opacity: 1;
  overflow: hidden;
  transition: opacity .5s ease-in-out;
  z-index: 4;
}
.About main {
  padding: 1rem;
  overflow-y: scroll;
  max-height: 86vh;
}
.About img.white * {
  fill: #ffffff;
}
.About button.close {
  background: transparent;
  border: 0;
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #ffffff;
}
.About a {
  color: #4385C2;
}
.About.hide {
  opacity: 0;
  bottom: 100%;
  top: initial;
  transition: opacity .5s ease-in-out;
}
.About .rando {
  background: #213C6D;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  border: 0;
  margin: 0 auto;
  padding: .5rem 1rem;
  width: calc(100% - 2rem);
}
.About .disclaimer {
  font-size: .75rem;
}

.MonthGenerator button.download {
  position: absolute;
  right: 1rem;
  margin-top: 1rem;
  padding: .45rem .61rem .55rem;
  border-radius: 100px;
  font-size: 1.5rem;
  background: #213C6D;
  color: #ffffff;
  border: 0;
  box-shadow: 1px 1px 5px #000000;
  z-index: 3;
}
.MonthGenerator button.zoom {
  position: absolute;
  right: 1.25rem;
  margin-top: 4.5rem;
  padding: .55rem .61rem .45rem;
  border-radius: 100px;
  font-size: 1rem;
  background: #ffffff;
  color: #213C6D;
  border: 0;
  box-shadow: 1px 1px 5px #000000;
  z-index: 3;
}
.MonthGenerator button.zoom.active {
  background: #213C6D;
  color: #ffffff;
}
.output {
  position: relative;
  padding: 1rem;
  width: calc(100vw - 2rem);
  height: calc(100vw - 2rem);
  max-height: 100vh;
  max-width: 100vh;
  margin: auto;
  background-size: cover;
}
.output.zoom {
  position: relative;
  padding: 1rem;
  width: calc(800px - 2rem);
  height: calc(800px - 2rem);
  margin: auto;
  overflow: scroll;
}
.output main {
  columns: 2;
  margin: 0 1rem;
}
.output h2 {
  text-shadow: 1px 1px 5px #000000;
  margin-top: 0;
  text-align: center;
  color: #ffffff;
  width: 100%;
  font-family: 'dolce_vita', 'Arial', sans-serif;
}
.output.invertFont h2 {
  text-shadow: none;
  color: #284B7E;
}

/* HEADLINE FONT SIZES */
.output h2.xsm {
  font-size: 1.6rem;
}
.output h2.sm {
  font-size: 1.8rem;
}
.output h2.med {
  font-size: 2rem;
}
.output h2.lg {
  font-size: 2.2rem;
}
.output h2.xlg {
  font-size: 2.4rem;
}

.output .logo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 2rem);
  color: #ffffff;
  background: #284B7E;
  padding: 1rem;
  text-align: right;
}
.output .logo img {
  width: 50%;
  clear: both;
}
.output .logo span {
  clear: both;
  display: block;
  font-size: .75rem;
}
button.zoom { display: none; }

.ChallengeControl {
  color: #ffffff;
  overflow: hidden;
  text-shadow: 1px 1px 3px #000000;
}
.ChallengeControl .display {
  font-size: 1.2rem;
  padding-bottom: .25rem;
}
.ChallengeControl .display button {
  background: transparent;
  border: 0;
  width: 100%;
  text-align: left;
  color: #ffffff;
  text-shadow: 1px 1px 1px #000000;
}
.invertFont .ChallengeControl .display button {
  text-shadow: none;
  color: #284B7E;
}

/* MAIN BODY FONT SIZES */

main.xsm .ChallengeControl .display button {
  font-size: 1rem;
}
main.sm .ChallengeControl .display button {
  font-size: 1.05rem;
}
main.med .ChallengeControl .display button {
  font-size: 1.1rem;
}
main.lg .ChallengeControl .display button {
  font-size: 1.15rem;
}
main.xlg .ChallengeControl .display button {
  font-size: 1.2rem;
}

.ChallengeControl .control {
  position: absolute;
  top: 40vh;
  left: 200vw;
  width: calc(100% - 2rem);
  background: #284B7E;
  padding: 1rem;
  max-width: 100vw;
  transition: left .5s ease-in-out;
  font-family: 'open_sans', 'Arial', sans-serif;
  text-shadow: none;
  letter-spacing: initial;
  z-index: 3;
  opacity: 0;
}
.ChallengeControl.open{
  border: 1px solid red;
}
.ChallengeControl.open .control{
  left: 0;
  opacity: 1;
  transition: left .5s ease-in-out;
}
.ChallengeControl .control .wrapper {
  position: relative;
  width: 100%;
}
.ChallengeControl .control .wrapper .close {
  font-size: 2rem;
}
.ChallengeControl label {
  font-size: 1.2rem;
  padding: .5rem 0;
}
.ChallengeControl ul {
  position: absolute;
  top: -0.5rem;
  right: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.ChallengeControl ul li {
  margin: 0;
  padding: 0;
  float: left;
}
.ChallengeControl ul li button {
  background: transparent;
  border: 0;
  color: white;
  font-size: 1.75rem;
  padding: 0 0.5rem;
}
.ChallengeControl ul li button.showBuilder {
  color: lightgray;
}
.ChallengeControl span {
  float: left;
  display: block;
}
.ChallengeControl input {
  background: #213C6D;
  color: #ffffff;
  font-size: 1.5rem;
  padding: .5rem;
  width: calc(100% - 1rem);
  border: 0;
  margin-top: .5rem;
}
.ChallengeControl .builder {
  color: #ffffff;
  font-size: 1.5rem;
  padding: .5rem;
  margin-top: .5rem;
}
.ChallengeControl .builder select {
  background: #213C6D;
  color: #ffffff;
  border: 0;
  padding: .25rem;
  font-size: 1.5rem;
  margin: 0 .25rem;
}
.ChallengeControl .buttons button {
  background: #213C6D;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  border: 0;
  margin: 0 .25rem;
  padding: .5rem 1rem;
}

@media screen and (max-width: 700px) {
  nav button {
    margin-top: 0;
  }
}

@media screen and (max-width: 500px) {
  button.zoom { display: initial; }
  .About img.white {
    padding-right: 0;
    width: 80%;
  }
  nav { padding-right: 0 }
  nav img {
    max-width: 75%;
  }
  nav button {
    margin-top: .5rem;
    font-size: 1.5rem;
  }
  .Tray .font select {
    width: 77%;
  }
  .About main {
    max-height: 95vh;
  }

  /* MOBILE FONT SIZES */
  .output:not(.zoom) h2.xsm {
    font-size: .6rem;
  }
  .output:not(.zoom) h2.sm {
    font-size: .8rem;
  }
  .output:not(.zoom) h2.med {
    font-size: 1rem;
  }
  .output:not(.zoom) h2.lg {
    font-size: 1.2rem;
  }
  .output:not(.zoom) h2.xlg {
    font-size: 1.4rem;
  }

  .output:not(.zoom) h2.synthetic.xsm {
    font-size: .9rem;
  }
  .output:not(.zoom) h2.synthetic.sm {
    font-size: 1.1rem;
  }
  .output:not(.zoom) h2.synthetic.med {
    font-size: 1.3rem;
  }
  .output:not(.zoom) h2.synthetic.lg {
    font-size: 1.5rem;
  }
  .output:not(.zoom) h2.synthetic.xlg {
    font-size: 1.8rem;
  }

  .output:not(.zoom) main.xsm .ChallengeControl .display button {
    font-size: .4rem;
  }
  .output:not(.zoom) main.sm .ChallengeControl .display button {
    font-size: .45rem;
  }
  .output:not(.zoom) main.med .ChallengeControl .display button {
    font-size: .5rem;
  }
  .output:not(.zoom) main.lg .ChallengeControl .display button {
    font-size: .55rem;
  }
  .output:not(.zoom) main.xlg .ChallengeControl .display button {
    font-size: .6rem;
  }

  .output:not(.zoom) main.xsm .ChallengeControl .display {
    font-size: .4rem;
    padding-bottom: 0;
  }
  .output:not(.zoom) main.sm .ChallengeControl .display {
    font-size: .45rem;
    padding-bottom: 0;
  }
  .output:not(.zoom) main.med .ChallengeControl .display {
    font-size: .5rem;
    padding-bottom: 0;
  }
  .output:not(.zoom) main.lg .ChallengeControl .display {
    font-size: .55rem;
    padding-bottom: 0;
  }
  .output:not(.zoom) main.xlg .ChallengeControl .display {
    font-size: .6rem;
    padding-bottom: 0;
  }
}

@keyframes shake {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 0.5rem;
  }
  100% {
    margin-top: 0;
  }
}
